// stylelint-disable scss/selector-no-redundant-nesting-selector
& {
  @each $color, $value in $theme-colors-pss {
    --#{$prefix}#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors-rgb-pss {
    --#{$prefix}#{$color}-rgb: #{$value};
  }

  //   --#{$prefix}body-color-rgb: #{to-rgb($body-color-pss)};
  --#{$prefix}body-bg-rgb: #{to-rgb($body-bg-pss)};

  --#{$prefix}link-color: #{$link-color-pss};
  --#{$prefix}link-color-rgb: #{to-rgb($link-color-pss)};

  // Gradients
  //   @each $color, $map in $theme-gradients-pss {
  //     --#{$prefix}#{$color}-start: #{map-get($map, "start")};
  //     --#{$prefix}#{$color}-stop: #{map-get($map, "stop")};
  //   }
}
