//Medium (md) and larger screens
@include media-breakpoint-up(md) {
.card-border {
    & {
      background-color: #F5F7FF;
      overflow-y: scroll;
    }
  }
  .card2-border {
    & {
      background-color: #F5F7FF;
    }
  }
  .cardsuspendloc-border {
    & {
      background-color: #F5F7FF;
      height: auto;
    }
  }
  .card-dashboardapprover {
    & {
      height: 230px;
    }
  }
  .cardtable-border {
    & {
      background-color: #F5F7FF;
      margin-top: 2px;
      height: auto;
      flex: 1;
      display: flex;
    }
  }
  .cardtable2-border {
    & {
      margin-top: 2px;
      // height: 680px; 
      height: auto;
      flex: 1;
    }
  }
  .cardtable-row {
    display: flex;
    align-items: stretch;
  }
& .alert-container {
    .alert-title{
        font-weight: 1000;
        font-size: 18px;
        margin-bottom: 10px;
    }
    .alert-detail {
        background-color: #ebedef;
        text-align: center;
        margin: 2%;
    }
    .alert-span{
        color: darkred;
        font-size: 28px;
    }
}

& .info-container{
    .info-title{
        font-weight: 1000;
        font-size: 18px;
    }
    .info-number{
        color: #2d3569;
        font-size: 28px;
        text-align: center;
    }
    .info-span{
        color: black;
        font-size: 15px;
    }
    .sus-span{
        color: red;
        font-size: 28px;
    }
}
.pie-chart {
    width: 200px;
    height: 200px;
  }
  .pie-doughnut {
    width: 250px;
    height: 250px;
}
.bar-chart {
  width: 70%;
  margin: 50px auto;
  text-align: center;
}
.info-height {
    height: 33%;
}

.chart-title {
    font-weight: bold;
    text-decoration: underline;
}
.today-title{
  text-align: left;
  font-weight: 1000;
  font-size: 15px;
  color: grey;
}
.cardapproved-background {
  background: linear-gradient(to bottom, #7fe65c 35%, #357733 100%);
  display: flex;
}
.cardpending-background {
  background: linear-gradient(to bottom, #EFA855 35%,  #cb7008 100%);
  display: flex;

}
.cardrejected-background {
  background: linear-gradient(to bottom, #F65353 35%,  #A03030 100%);
  display: flex;

}
}

& .card-container{
  .card-title{
      text-align: end;
      margin-right: 5px;
      font-weight: 1000;
      font-size: 18px;
      color: grey;
      margin-top: 1px;
  }
  .card-number{
      text-align: end;
      margin-right: 50px;
      font-size: 48px;
      font-weight: 700;
      margin-top: 50px;
  }
}

.card-number2{
  text-align: start;
  // justify-content: end;
  // margin-right: 50px;
  font-size: 55px;
  font-weight: 700;
  color:green;
  // margin-top: 50px;
}

.card-title2{
  text-align: center;
  // margin-right: 5px;
  font-weight: 1000;
  font-size: 20px;
  color:white;
  // margin-top: 1px;
}

// Small screens
@include media-breakpoint-down(md) {
  .card-border {
    & {
      background-color: #F5F7FF;
      margin-top: 10px;
      overflow-y: scroll;
    }
  }
  .card2-border {
    & {
      background-color: #F5F7FF;
    }
  }
  .cardsuspendloc-border {
    & {
      background-color: #F5F7FF;
      height: 180px; 
      margin-top: 1px;
    }
  }
  .card-dashboardapprover {
    & {
      height: 230px;
    }
  }
  .cardtable-border {
    & {
      background-color: #F5F7FF;
      margin-top: 2px;
      height: auto;
      flex: 1;
    }
  }
  .cardtable2-border {
    & {
      margin-top: 2px;
      height: auto;
      flex: 1;
    }
  }
  .cardtable-row {
    display: flex;
    align-items: stretch;
  }
  & .alert-container {
    .alert-title{
        font-weight: 1000;
        font-size: 18px;
        margin-bottom: 10px;
    }
    .alert-detail {
        background-color: #ebedef;
        text-align: center;
        margin: 2%;
    }
    .alert-span{
        color: darkred;
        font-size: 28px;
    }
}

& .info-container{
    .info-title{
        font-weight: 1000;
        font-size: 18px;
    }
    .info-number{
        color: #2d3569;
        font-size: 28px;
        text-align: center;
    }
    .info-span{
        color: black;
        font-size: 15px;
    }
    .sus-span{
        color: red;
        font-size: 28px;
    }
}
.pie-chart {
    width: 200px;
    height: 200px;
  }
  .pie-doughnut {
    width: 250px;
    height: 250px;
}
.bar-chart {
  width: 100%;
  margin: 50px auto;
  text-align: center;
}
.info-height {
    height: 33%;
}

.chart-title {
    font-weight: bold;
    text-decoration: underline;
}
.today-title{
  text-align: left;
  font-weight: 1000;
  font-size: 15px;
  color: grey;
}
.cardapproved-background {
  background: linear-gradient(to bottom, #86E864 35%,  #357733 100%);

}
.cardpending-background {
  background: linear-gradient(to bottom, #EFA855 35%,  #CF7308 100%);
}
.cardrejected-background {
  background: linear-gradient(to bottom, #F65353 35%,  #A03030 100%);
}
}

& .card-container{
  .card-title{
      text-align: end;
      margin-right: 50px;
      font-weight: 1000;
      font-size: 18px;
      color: grey;
  }
  .card-number{
      text-align: end;
      margin-right: 50px;
      font-size: 48px;
      font-weight: 700;
  }
}

