.pss-light {
  @import "./variables";
  @import "./root";
  @import "./utilities";
  @import "./custom";
  @import "./dashboard";
  @import "./print";

  @include theme($theme-map);

  // Button variants
  @each $state, $variant in $button-variants-pss {
    .btn-#{$state} {
      @include button-variant($variant);
    }
  }

  @each $state, $variant in $button-outline-ghost-variants-pss {
    .btn-outline-#{$state} {
      @include button-outline-variant($variant);
    }
  }

  @each $state, $variant in $button-outline-ghost-variants-pss {
    .btn-ghost-#{$state} {
      @include button-ghost-variant($variant);
    }
  }
}

.pss-dark {
  font-size: 0.9rem;
}

.pss-light,
.pss-dark {
  font-family:
    Poppins-Regular,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    "Noto Sans",
    "Liberation Sans",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";

  font-size: 0.9rem;

  // features
  // @import "../features/door-access/sass/door.scss";

  // components
  @import "./utilities";
  @import "./custom";
  @import "./dashboard";
  @import "./print";

}
