.activepermitprint {
  &.editor,
  .col-form-label-value {
    font-weight: bold;
    font-style: italic;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
  }

  .report {
    text-align: center;
  }

  .big-title {
    margin-bottom: 30px;
  }

  .sub-title {
    margin-bottom: 50px;
  }
  th,
  td {
    font-size: 0.8rem;
  }
}
.company-title {
  text-align: center;
  font-family: Arial, sans-serif;
}

.company-title h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.company-info {
  font-size: 15px;
  text-align: center;
  font-weight: bold;
}

.button-label {
  &.print,
  &.submit {
    width: 20vh;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

.container {
  padding-top: 50px;
  padding-bottom: 100px;
}

.recipient-table {
  margin: 20px auto;
}

.center-content {
  vertical-align: middle;
  height: 100%; 
}
.worker-details-row {
  background-color: #ffffff;
  text-align: center;

  .worker-details-cell {
    background-color: inherit;
    text-align: center;

  }
}
.worker-details-header {
  background-color: #9aa3c7 inherit;
  text-align: center;
}
.small-width {
  @media (min-width: 768px) {
    width: 300px;
  }
  @media (max-width: 767px) {
    width: 300px;
  }
}
.table-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

.recipient-table-preview {
  width: 32%;
  margin-bottom: 1rem;

  .small-width-header {
    font-weight: bold;
    text-align: center;
  }

  .small-width-preview {
    width: 60%;
  }

  .small-width-preview2 {
    width: 40%;
    text-align: center;
  }

  .small-width {
    width: 60%;
    padding-left: 1rem;
  }

  .center-content {
    width: 40%;
    text-align: center;
  }
}

.col-print {
  padding: 10px 100px;
  margin-bottom: 10px;
}
$gap: 10px;

.footnote-bottom {
  font-size: 10px;
  margin-bottom: 10px;
  p {
    &:nth-child(2),
    &:nth-child(3) {
      margin-bottom: 0;
    }

    &:nth-child(5) {
      span {
        margin-right: $gap;
      }
    }
  }
}

@media print {
  .police-img {
    width: 130px;
    height: 100px;
  }
  .report {
    text-align: center;
    font-size: 20px;
  }
  .sub-title {
    font-size: 15px;
  }
  .recipient-table {
    font-size: 10px;
  }
  .small-width {
    width: 200px;
  }
  .custom {
    font-size: 10px;
    font-weight: bold;
    text-align: center;
}
.company-title {
  text-align: center;
  font-family: Arial, sans-serif;
}

.company-info {
  font-size: 11px;
  text-align: center;
  font-weight: bold;
}
.today-title{
  text-align: left;
  font-weight: 1000;
  font-size: 15px;
  color: grey;
}
.table-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}
.recipient-table-preview {
  width: 32%;
  margin-bottom: 1rem;

  .small-width-header {
    font-weight: bold;
    text-align: center;
  }

  .small-width-preview {
    width: 60%;
  }

  .small-width-preview2 {
    width: 40%;
    text-align: center;
  }

  .small-width {
    width: 60%;
    padding-left: 1rem;
  }

  .center-content {
    width: 40%;
    text-align: center;
  }
}
  .center-content {
    vertical-align: middle;
    height: 100%; 
  }
  .worker-details-row {
    background-color: #ffffff;
    text-align: center;

    .worker-details-cell {
      background-color: inherit;
      text-align: center;

    }
  }
  .footnote-bottom {
    font-size: 10px;
    margin-bottom: 10px;
    p {
      &:nth-child(2),
      &:nth-child(3) {
        margin-bottom: 0;
      }
  
      &:nth-child(5) {
        span {
          margin-right: $gap;
        }
      }
    }
  }
  
  .worker-details-header {
    background-color: #9aa3c7 inherit;
    text-align: center;
  }
  @page {
    size: auto;
  }
}
