//Medium (md) and larger screens
@include media-breakpoint-up(md) {
  .ccard-body{
    margin-left: 20px;
    margin-top: 10px
  }
  .hidden{
    display: none;
  }
  .action-background {
    background-color: #808080; 
    color: white; 
  }
  .custom-button {
    margin-top: 30px;
    width: 120px; 
    height: 38px; 
    font-size: 15px; 
    padding: 0.5rem 1rem; 
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
  .display-form {
    border: 1px solid #999;
  }
  .custom2-button {
    margin-top: 10px;
    width: 150px; 
    height: 38px; 
    font-size: 13px; 
    padding: 0.5rem 1rem; 
    gap: 10px; 
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
  }
  .custom3-button {
    margin-top: 10px;
    width: 180px; 
    height: 38px; 
    font-size: 13px; 
    padding: 0.5rem 1rem; 
  }
  .deleteBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 60px;
    margin-bottom: -10px;
  }
  .doneBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: -10px;
  }
  
  .doneAlert {
    color: white;
    background-color: #2d3569;
    border-radius: 50%;
    font-size: 35px;
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .container-summary {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 70vh;
    margin-left: 320px;
  }
  
  .card-body-summary {
    background-color: rgba(217, 209, 235, 0.852); /* Adjust the last value (0.5) to change the transparency level */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
}
  .deleteAlert {
    color: white;
    background-color: #2d3569;
    border-radius: 50%;
    font-size: 50px;
    height: 80px;
    width: 80px;
    margin-top: 60px;
    margin-bottom: 30px;
    margin-left: 210px;
  }
  .ongoing-badge {
    background-color: #28a745; 
    color: #fff;
    padding: 2px 6px; 
    border-radius: 4px; 
    font-size: 12px; 
  }
  .card-report {
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  // .profilePicture{
  //   margin-top: -14%;
  // }

  .report-thumbnail {
    max-width: 200px;
    width: 100%;
    height: auto;
    cursor: pointer;
  }

} 
// Small screens
@include media-breakpoint-down(md) {

  .profilePicture{
    margin-top: -30%;
  }

  .ccard-body{
    margin-left: 20px;
    margin-top: 10px
  }
  .hidden{
    display: none;
  }
  .action-background {
    background-color: #808080; 
    color: white; 
  }
  .custom-button {
    margin-top: 30px;
    width: 120px; 
    height: 38px; 
    font-size: 15px; 
    padding: 0.5rem 1rem; 
  }
  .button-container {
    display: flex;
    justify-content: center;
  }
  .custom2-button {
    margin-top: 10px;
    width: 150px; 
    height: 38px; 
    font-size: 13px; 
    padding: 0.5rem 1rem; 
    gap: 10px; 
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    justify-content: center;

  }
  .custom3-button {
    margin-top: 10px;
    width: 180px; 
    height: 38px; 
    font-size: 13px; 
    padding: 0.5rem 1rem; 
    justify-content: center;

  }
  .custom-workingLocation {
    margin-left: 20px;
  }
  .display-form {
    border: 1px solid #999;
  }
  .deleteAlert {
    color: white;
    background-color: #2d3569;
    border-radius: 50%;
    font-size: 50px;
    height: 80px;
    width: 80px;
    margin-top: 60px;
    margin-bottom: 30px;
    margin-left: 130px;
  }
  .deleteBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 60px;
    margin-bottom: -10px;
  }
  .doneBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: -10px;
  }
  
  .doneAlert {
    color: white;
    background-color: #2d3569;
    border-radius: 50%;
    font-size: 35px;
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .ongoing-badge {
    background-color: #28a745; 
    color: #fff;
    padding: 2px 6px; 
    border-radius: 4px; 
    font-size: 12px; 
  }
  .card-report {
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .report-thumbnail {
    max-width: 100px;
    width: 100%;
    height: auto;
    cursor: pointer;
  }
} 

.cardTitle-button {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
}

.cardTitle-pending {
  font-size: 18px;
  margin-top: 5px;
}

.card-color {
  background-color: #F5F7FF;
}

.success-icon{
  margin-top: 50px;
  margin-bottom: 40px;
  font-size: 35px;
  width: 50px;
  height: 50px;
  color: white;
  background-color: #20be79;
  box-shadow:
    0 0 0px 13px rgba(154,226,194,255), 0 0 0px 25px rgba(197,238,220,255);
  border-radius: 50%;
  padding: 10px;
}

.custom-input {
  position: relative;
}

.custom-input ~ .invalid-feedback {
  position: absolute;
  bottom: -1.5rem; 
  left: 0;
  width: 100%;
  display: block;
}

.custom-header-cell {
  background-color: #102A71;
  color: white;
}

.approval-modal {
    margin-top: 50px;
    margin-bottom: 40px;
    font-size: 35px;
    width: 50px;
    height: 50px;
    color: white;
    background-color: #8f9492;
    box-shadow:
    0 0 0px 13px rgba(210,210,210), 0 0 0px 25px rgba(240,240,240);
    border-radius: 50%;
    padding: 10px;
  }

  .status-badge {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    padding: 0.3rem 1.5rem;
    margin: 0 0.25rem;
    border-radius: 1.25rem;
  }

  .custom-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1.5rem; 
    font-size: 0.8rem;
    margin: 0 auto;
    border-radius: 1.25rem;
    min-width: 80px;
    max-width: 120px;
    white-space: nowrap;
    
  }

  .closing-badge {
    align-items: center;
    justify-content: center;
    padding: 0.3rem 2rem;
    font-size: 0.8rem;
    margin: 0 0.25rem;
    border-radius: 1.25rem;
    text-align: center;
  }


  .badge-circle {
    display: inline-flex;       
    align-items: center;      
    justify-content: center;    
    width: 20px;              
    height: 20px;              
    border-radius: 50%;       
    font-size: 12px;         
  }
  
