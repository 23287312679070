// If you want to override variables do it here
// // --------------------------------------------------------------------
// // Fonts
// // --------------------------------------------------------------------
@font-face {
  font-family: Poppins-Regular;
  src: url("poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Montserrat-Bold;
  src: url("montserrat/Montserrat-Bold.ttf");
}
@font-face {
  font-family: Montserrat-Regular;
  src: url("montserrat/Montserrat-Regular.ttf");
}
