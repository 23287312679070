//# Fix breadcrumb scss bug
.dark-theme {
  .breadcrumb {
    --#{$prefix}breadcrumb-item-active-color: var(
      --#{$prefix}breadcrumb-active-color
    );
  }
}

// Disable microsoft edge show password
input::-ms-reveal {
  display: none;
}
input::-ms-clear {
  display: none;
}

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1400px
// );
/** 
*  |      xs      |      sm    |     md    |      lg     |      xl     |     xxl 
*  0px----------576px--------768px-------992px---------1200px--------1400px-----------------
*   xs = true   <-|  xs = false                                                             |max-width: 576px
*  -----------------------------------------------------------------------------------------
*   sm = false    |->  sm = true                                                            |min-width: 576px
*  -----------------------------------------------------------------------------------------
*   md = false                 |-> md = true                                                |min-width: 768px  
*  -----------------------------------------------------------------------------------------
*   lg = false                             |-> lg  = true                                   |min-width: 992px 
*  -----------------------------------------------------------------------------------------
*   xl = false                                             |-> xl  = true                   |min-width: 1200px 
*  -----------------------------------------------------------------------------------------
*   xxl= false                                                          |-> xxl  = true     |min-width: 1400px 
*  -----------------------------------------------------------------------------------------
*/
@media (max-width: 576px) {
  .hidden-max-sm {
    display: none;
  }
}
@media (min-width: 576px) {
  .hidden-min-sm {
    display: none;
  }
}
@media (min-width: 768px) {
  .hidden-min-md {
    display: none;
  }
}
@media (min-width: 992px) {
  .hidden-min-lg {
    display: none;
  }
}
@media (min-width: 1200px) {
  .hidden-min-xl {
    display: none;
  }
}
@media (min-width: 1400px) {
  .hidden-min-xxl {
    display: none;
  }
}
/**
*  |      xs      |      sm    |     md    |      lg     |      xl     |     xxl 
*  0px----------576px--------768px-------992px---------1200px--------1400px-----------------
*   xs = false    |-> xs = true                                                             |min-width: 576px
*  -----------------------------------------------------------------------------------------
*   sm = true   <-|  sm = false                                                             |max-width: 576px
*  -----------------------------------------------------------------------------------------
*   md = true                <-| md = false                                                 |max-width: 768px  
*  -----------------------------------------------------------------------------------------
*   lg = true                             <-| lg  = false                                   |max-width: 992px 
*  -----------------------------------------------------------------------------------------
*   xl = true                                             <-| xl  = false                   |max-width: 1200px 
*  -----------------------------------------------------------------------------------------
*   xxl= true                                                          <-| xxl  = false     |max-width: 1400px 
*  -----------------------------------------------------------------------------------------
*/
@media (min-width: 576px) {
  .hidden-min-xs {
    display: none;
  }
}
@media (max-width: 576px) {
  .hidden-max-sm {
    display: none;
  }
}
@media (max-width: 768px) {
  .hidden-max-md {
    display: none;
  }
}
@media (max-width: 992px) {
  .hidden-max-lg {
    display: none;
  }
}
@media (max-width: 1200px) {
  .hidden-max-xl {
    display: none;
  }
}
@media (max-width: 1400px) {
  .hidden-max-xxl {
    display: none;
  }
}
