& .text-underline {
  text-decoration: underline;
}

& .cms-bg-sub-light {
  background-color: #e6d0d9;
  color: black;
}
& .cms-bg-sub-dark {
  background-color: #e7e5f5;
  color: black;
}

& .h-200px {
  height: 200px;
}
& .h-128px {
  height: 128px;
}

& .w-100px {
  width: 100px;
}

& .cursor-pointer {
  cursor: pointer;
}

& .fs-08 {
  font-size: 0.8rem;
}

& .p-08 {
  padding: 2px;
}

& .p-0155rem {
  padding: 0.155rem;
}

& .bg-gray-100 {
  background-color: #fbfbfb;
}

& .pointer-default {
  cursor: default;
}
