// Sign In
$userSignIn-background: url('../assets/images/login-background.png');
// $userSignIn-mobile: url('../../assets/images/login-mobile.png');

.border-login {
  border-right: none;
}
.btn-login {
  width: 80%;
}
.userSignIn {
  background-image: $userSignIn-background;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  object-fit: fit-content;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px); /* Adjust the value as needed */
}
//Medium (md) and larger screens
@include media-breakpoint-up(md) {
  // Login
  .userSignIn {
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .userSignIn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: $userSignIn-background;
    background-size: cover;
    background-position: center;
    filter: blur(2px);
    z-index: -1;
  }
  
  .card-login {
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    background: white;
    border-radius: 1rem;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    z-index: 10;

  }
  .border-login {
    border-right: 1px solid gray;
  }
  .btn-login {
    width: 40%;
  }
  .link-rememberme {
    font-size: 12px;
    margin-right: 10px;
    margin-top: -50px;
    color: #00aca9;
  }
  .link-forgotPass {
    font-size: 12px;
    margin-left: 65px;
    margin-top: -7px;
    // color: #246aab;
    color: #00aca9;

  }
  .tlp-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:8%;
    margin-top: 8%;
    margin-left: 60px;
    padding: 1rem 0; /* Adjust padding as needed */
  }
  
  .tlp-logo img {
    max-width: 100px;
    height: auto;
  }
  .footer-text {
    position: fixed;
    bottom: 0.5rem;
    right: 10rem;
    color: #d8eef0;
    font-size: 12px;
    text-align: center;
  }
  .text-login {
    width: 80%;
    position: relative;
    left: 20%;
  }
  @media (min-width: 5px) {
    .input-group-responsive {
      max-width: calc(71% - 10px);
    }
  }

  .title1-login {
    font-size: 15px;
    text-align: center;
    margin-left: 60px;
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: bold; /* Add this line to make the text bold */
  }
  .title2-login {
    font-size: 20px;
    text-align: center;
    margin-left: 60px;
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: bold; /* Add this line to make the text bold */
  }
  .nav-tabs1 {
    display: flex;
    list-style-type: none;
    padding: 0.5rem 1rem;
    margin-bottom: 7px;
    margin-left: 60px;
    font-size: -1rem;
  }
  
  .nav-tabs2 {
    margin-right: 5px;
  }
  
  .nav-tabs1 .nav-link.active {
    background-color: #00aca9; /* Active tab color */
    color: #fff;
  }
  
  .nav-tabs1 .nav-link {
    background-color: #ffffff; /* Inactive tab color */
    color: #333;
  }
  
  .btnSignIn {
    width: 70%;
    position: relative;
    left: 20%;
    background-color: #00aca9;
    border-radius: 10px;
    color: white;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .custom-alert {
    width: 70%;
    height: 15px;
    position: relative;
    display:flex;
    left: 20%;
    color: "danger";
    border-radius: 10px;
    color: #893232;
    margin-top: 1%;
    margin-bottom: 10%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .btnSignUp {
    width: 80%;
    position: relative;
    left: 10%;
    background-color: #00aca9;
    border-radius: 10px;
    color: white;
    margin-top: 3%;
    margin-bottom: 1%;
  }
}
// Small screens
@include media-breakpoint-down(md) {
  //login
  .userSignIn {
    // background-image: $userSignIn-mobile;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
  }
  .card-login {
    border-radius: 1rem;
    width: 90%;
    margin-top: 40px;
  }
  .link-register {
    font-size: 12px;
    margin-left: 2px;
    color: #246aab;
  }
  .link-forgotPass {
    font-size: 12px;
    margin-right: 2px;
    color: #246aab;
  }
  .footer-text {
    text-align: center;
    color: #d8eef0;
    font-size: 9px;
    margin-top: auto;
  }
  .text-login {
    width: 110%;
    position: relative;
  }
  @media (min-width: 3px) {
    .input-group-responsive {
      max-width: calc(98% - 10px);
    }
  }
  .tlp-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:10%;
    margin-top: 10%;
    margin-left: 5px;
    padding: 1rem 0; /* Adjust padding as needed */
  }
  
  .tlp-logo img {
    max-width: 100px;
    height: auto;
  }
  .title1-login {
    font-size: 15px;
    text-align: center;
    margin-left: 6px;
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: bold; /* Add this line to make the text bold */
  }
  .title2-login {
    font-size: 20px;
    text-align: center;
    margin-left: 6px;
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: bold; /* Add this line to make the text bold */
  }
  .nav-tabs1 {
    display: flex;
    list-style-type: none;
    padding: 0.5rem 1rem;
    margin-bottom: 7px;
    margin-left: 15px;
    font-size: -1rem;
  }
  
  .nav-tabs2 {
    margin-right: 5px;
  }
  
  .nav-tabs1 .nav-link.active {
    background-color: #00aca9; /* Active tab color */
    color: #fff;
  }
  
  .nav-tabs1 .nav-link {
    background-color: #ffffff; /* Inactive tab color */
    color: #333;
  }
  .link-rememberme {
    font-size: 12px;
    margin-right: 30px;
    margin-top: -50px;
    color: #00aca9;
  }
  .link-forgotPass {
    font-size: 12px;
    margin-left: -10px;
    margin-top: -7px;
    // color: #246aab;
    color: #00aca9;

  }
  .btnSignIn {
    width: 100%;
    position: relative;
    background-color: #00aca9;
    border-radius: 10px;
    color: white;
  }
  .custom-alert {
    width: 100%;
    height: 15px;
    position: relative;
    display:flex;
    // left: 1%;
    color: "danger";
    border-radius: 10px;
    color: #893232;
    margin-top: 3%;
    margin-bottom: 10%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .btnSignUp {
    width: 100%;
    position: relative;
    background-color: #00aca9;
    border-radius: 10px;
    color: white;
    margin-top: 3%;
    margin-bottom: 1%;
  }
}
